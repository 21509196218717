@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=block');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Poppins', 'Helvetica Neue', Arial, sans-serif;
  height: 100%;
  overflow: auto;
  /* -webkit-overflow-scrolling: touch; */
  /* We add this lines (height: 100%; overflow: auto;-webkit-overflow-scrolling: touch;) because of safari ios 16, it does a weird scrolling issue after displaying the keyboard. (extra bottom height and new scrolling bar)  */
}

body {
  font-family: 'Poppins', 'Helvetica Neue', Arial, sans-serif;
  height: 100%;
  overflow: auto;
  /* -webkit-overflow-scrolling: touch; */
  /* We add this lines (height: 100%; overflow: auto;-webkit-overflow-scrolling: touch;) because of safari ios 16, it does a weird scrolling issue after displaying the keyboard. (extra bottom height and new scrolling bar)  */
  min-height: 100%;
  margin: 0px;
  /* this turns off margin collapsing for internal content to make proper iframe height */
  display: flex;
  flex-direction: column;
}

#scroll-anchor {
  display: block;
  width: 100%;
  height: 2px;
  position: relative;
}

#root {
  /* affects scrollbar flickering since body content can go very tall */
}

.global--caption {
  margin-bottom: 30px;
}

.global--toast {
  --toastify-text-color-light: black;
  --toastify-font-family: initial;
}
.global--toast .Toastify__toast {
  @apply text-black p-4 rounded-2xl shadow-xl;
}

.global--label {
  line-height: 1.33;
}

.react-datepicker-popper {
  z-index: 2001 !important; /* Because navigation bar is set to 20 and the other top bar 2000 and sometime it goes below */
}
