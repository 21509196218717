.input-async-search--text-container {
  width: 100%;
}

.input-async-search--text-input-label {
  pointer-events: none;
  position: absolute;
  left: 22px;
  top: 13px;
  width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: rgb(136 141 145 / 1);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.input-async-search--text-input-label-text {
  border-radius: 25px;
  background-color: transparent;
  font-size: 16px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.input-async-search--input:focus ~ .input-async-search--text-input-label,
.input-async-search--input.global--with-value ~ .input-async-search--text-input-label {
  top: -8px;
  left: 16px;

  color: rgb(136 141 145 / 1);
}

.input-async-search--input:focus ~ .input-async-search--text-input-label .input-async-search--text-input-label-text,
.input-async-search--input.global--with-value ~ .input-async-search--text-input-label .input-async-search--text-input-label-text {
  background-color: rgb(255 255 255 / 1);
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 11px;
}

.input-async-search--options-box {
  top: 51px;
  left: 0;
  z-index: 2;
  border-radius: 25px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  background-color: #fff;
  box-shadow:
    0 20px 25px -5px #0000001a,
    0 8px 10px -6px #0000001a;
  max-height: 344px;
}

.input-async-search--select-input-options {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  max-height: 312px;
  padding: 8px 0;
  margin: 8px 12px 8px 16px;
  cursor: pointer;
}

.input-async-search--select-input-option {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 42px;
  width: 100%;
  padding: 0 16px;
  color: #13141a;
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.01rem;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

.input-async-search--select-input-option:hover {
  background-color: #eff1f5;
}

.input-async-search--input {
  width: 100%;
  border-radius: 25px;
  border-width: 1px;
  border-color: rgb(226 226 226 / 1);
  background-color: rgb(255 255 255 / 1);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 22px;
  padding-right: 18px;
  font-weight: 400;
  outline: none;

  color: rgb(136 141 145 / 1);
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.input-async-search--input:focus, .input-async-search--input:hover {
  border-color: #2e61ef;
  background-color: #e8eff9;
}

.input-async-search--input:disabled {
  background-color: rgb(247 247 247 / 1) !important;
}

.input-async-search--input.global--with-value {
  border-color: transparent;

  background-color: rgb(247 247 247 / 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #4e5972;
}

/* Input Container */

.input-async-search--input-container {
  position: relative;
  margin-bottom: 1.5rem;
}

/* Input Label */
.input-async-search--input-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: #4e5972;
}

/* Input Error */
.input-async-search--input-error {
  font-size: 13px;
  font-weight: 500;
  margin-top: 0.5rem;
  color: #ff5368;
}

/* Input Label animation */

.input-async-search--input:focus ~ .input--transition-label,
.input-async-search--input.global--with-value ~ .input--transition-label {
  top: -8px;
  left: 16px;

  background-color: rgb(255 255 255 / 1);
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 11px;

  color: #4e5972;
}

.input-async-search--input.hide-autocomplete::-webkit-contacts-auto-fill-button {
  display: none !important;
  visibility: hidden !important;
}


.input-async-search--loader {
  width: 36px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#2e61ef 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#2e61ef);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13{ 
  100%{transform: rotate(1turn)}
}