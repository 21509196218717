.header--progress-bar-title {
  white-space: nowrap;
  padding-right: 5.5rem;
  font-size: 30px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: -1px;
  --tw-text-opacity: 1;
  color: rgb(19 20 26 / var(--tw-text-opacity));
}
