.loading-indicator {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100vh;
    color: #4b4b4b;
    transition: opacity 350ms ease-out .1s;
    opacity: 1
}

.loading-indicator--hidden {
    opacity: 0
}

.loading-indicator::after {
    content: "";
    -webkit-animation: rotateAnimation 1.2s infinite cubic-bezier(.23,1.2,.32,1);
    animation: rotateAnimation 1.2s infinite cubic-bezier(.23,1.2,.32,1);
    border-bottom: 2px solid #ebebeb;
    border-left: 2px solid #ebebeb;
    border-radius: 100%;
    border-right: 2px solid #ebebeb;
    border-top: 2px solid #949494;
    box-sizing: border-box;
    font: inherit;
    height: 45px;
    margin: 0 0 45px;
    padding: 0;
    width: 45px
}

@-webkit-keyframes rotateAnimation {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes rotateAnimation {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}