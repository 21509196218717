.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0px;
  z-index: 20;
  border-width: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden auto;
}

.popup {
  margin: auto;
  position: relative;
  width: 275px;
  border-radius: 25px;
  /* padding: 35px 25px 33px 25px; */
  background-color: white;
  box-shadow: 0 7px 50vmax 50vmax rgba(0, 0, 0, 0.2);
}

.popup-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 22px 33px;
}

.popup-close {
  position: absolute;
  top: 22px;
  right: 22px;
  height: 13px;
  width: 13px;
  cursor: pointer
}
