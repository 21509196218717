.steps-container {
  max-width: 622px;
  transition: border 0.2s ease;
  @apply sm:max-h-full;
}

.steps-container.scroll {
  border-top: 1px solid #d2d2d2;
  transition: border 0.2s ease;
}

.steps-container::-webkit-scrollbar-track {
  background-color: transparent;
  border-left: 0px solid #ccc;
  position: fixed;
  right: 0;
}
.steps-container::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: transparent;
  transition: all 0.3s ease;
}
.steps-container::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
  transition: all 0.3s ease;
}
